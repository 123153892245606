<template>
  <div>
    <ViewTemplateReportPreview :panel="panel" :templateList="templateList" :templateReport="templateReport" />
  </div>
</template>

<script>
import ViewTemplateReportPreview from "@nixweb/nixloc-ui/src/component/template/ViewTemplateReportPreview.vue";

export default {
  name: "ReportProductView",
  components: {
    ViewTemplateReportPreview,
  },
  data() {
    return {
      panel: {
        module: "Locação",
        title: "Locação por Pagamento",
        showFilter: false,
        showSearch: false,
        showButtons: false,
      },
      templateList: {
        urlGetApi: "/odata/RentByPaymentOData",
        showChecks: false,
      },
      templateReport: {
        fields: [
          {
            field: "number",
            title: "Número",
            type: "text",
            selected: true,
            requiredOData: false,
            classCssTitle: "text-center",
            classCssBody: "text-center",
          },
          {
            field: "identification",
            title: "Identificação",
            type: "text",
            selected: false,
            requiredOData: false,
          },
          {
            field: "typeRentName",
            title: "Tipo Locação",
            type: "text",
            selected: false,
            requiredOData: false,
          },
          {
            field: "collaboratorName",
            title: "Consultor",
            type: "text",
            selected: false,
            requiredOData: false,
            styleHeader: "width:150px;",
            styleBody: "max-width: 150px; white-space: nowrap;",
          },
          {
            field: "companyName",
            title: "Razão Social/Nome",
            type: "text",
            selected: true,
            requiredOData: false,
            styleHeader: "width:250px;",
            styleBody: "max-width: 250px; white-space: nowrap;",
          },
          {
            field: "tradeName",
            title: "Fantasia/Apelido",
            type: "text",
            selected: true,
            requiredOData: false,
            styleHeader: "width:150px;",
            styleBody: "max-width: 150px; white-space: nowrap;",
          },
          {
            field: "contactName",
            title: "Contato",
            type: "text",
            selected: false,
            requiredOData: false,
            styleHeader: "width:120px;",
            styleBody: "max-width: 120px; white-space: nowrap;",
          },
          {
            field: "statusRentalName",
            title: "St Locação",
            type: "text",
            selected: false,
            requiredOData: false,
            fieldComparison: "statusRentalName",
            classCssTitle: "text-center",
            classCssBody: "text-center",
            styleHeader: "width:130px;",
            styleBody: "max-width: 130px; white-space: nowrap;",
          },
          {
            field: "dueDate",
            title: "Vencimento",
            type: "date",
            orderBy: "asc",
            selected: true,
            requiredOData: false,
          },
          {
            field: "statusPayableReceivableName",
            title: "St Financeiro",
            type: "text",
            selected: true,
            requiredOData: false,
            fieldComparison: "statusRentalName",
            classCssTitle: "text-center",
            classCssBody: "text-center",
            styleHeader: "width:130px;",
            styleBody: "max-width: 130px; white-space: nowrap;",
          },
          {
            field: "value",
            title: "Valor",
            type: "currency",
            selected: true,
            requiredOData: false,
            styleHeader: "max-width:150px;",
            styleBody: "max-width: 150px; white-space: nowrap;",
          },
        ],
        rules: [
          {
            name: "dueDate",
            title: "Vencimento",
            icon: "fas fa-database",
            typeField: "dateTime",
            type: "field",
            props: {
              format: "DD/MM/YYYY",
              type: "date",
              range: true,
            },
            notDuplicate: true,
            isRequired: true,
          },
          {
            name: "number",
            title: "Número",
            icon: "fas fa-database",
            typeField: "numeric",
            type: "field",
            notDuplicate: false,
            isRequired: false,
          },
          {
            name: "identification",
            title: "Identificação",
            icon: "fas fa-database",
            typeField: "text",
            type: "field",
            notDuplicate: false,
            isRequired: false,
          },
          {
            name: "customerSupplierId",
            title: "Cliente",
            icon: "fas fa-database",
            typeField: "select",
            type: "field",
            props: {
              url: "/api/v1/crm/customer/select-all",
            },
            notDuplicate: false,
            isRequired: false,
          },
          {
            name: "statusRental",
            title: "Status Locação",
            icon: "fas fa-database",
            typeField: "checkbox",
            type: "field",
            options: [
              { title: "Orçamento", value: 1 },
              { title: "Aprovada", value: 2 },
              { title: "Reprovada", value: 3 },
            ],
            notDuplicate: true,
            isRequired: false,
          },
          {
            name: "statusPayableReceivable",
            title: "Status Financeiro",
            icon: "fas fa-database",
            typeField: "checkbox",
            type: "field",
            options: [
              { title: "Sem Vinculo", value: 1 },
              { title: "Pago", value: 2 },
              { title: "Recebido", value: 3 },
              { title: "Hoje", value: 4 },
              { title: "Pendente", value: 5 },
              { title: "Agendada", value: 6 },
            ],
            notDuplicate: true,
            isRequired: false,
          },
          {
            name: "value",
            title: "Total",
            icon: "fas fa-database",
            typeField: "numeric",
            type: "field",
            notDuplicate: false,
            isRequired: false,
          },
          {
            name: "value",
            title: "Totalização",
            icon: "fas fa-sigma",
            typeField: "checkbox",
            classCss: "primary",
            type: "totalization",
            options: [
              {
                title: "Total Valor",
                value: "sum_Value",
                aggregate: {
                  title: "Total Valor",
                  value: "sumValue",
                  type: "currency",
                },
              },
            ],
            notDuplicate: true,
            isRequired: false,
          },
        ],
      },
    };
  },
};
</script>
